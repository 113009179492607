import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout alt>
    <SEO title="404: Not found" />
      <div className="main-body-inner">
          <div className="wrapper">
              <h1 className="ct-title dark">NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
      </div>
  </Layout>
)

export default NotFoundPage
